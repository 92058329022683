<template>
  <div class="wrong-topic">
    <!--错题页面    -->
    <div class="mistakes">
      <h3>错题集</h3>
      <p>
        <input type="checkbox">
        <span>仅查看今日错题</span>
      </p>
      <!--错误的题目 -->
      <ul>
        <li v-for="i in 8" :key="i">
          <Qa withFaultCount />
        </li>
      </ul>
      <!--分页-->
      <pagination/>
    </div>
  </div>
</template>

<script>
import Qa from '@/components/question/qa'
export default {
  components: { Qa }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.wrong-topic{
  .mistakes{
    background-color: $background-color;
    padding: 0 20px;
    box-shadow: $background-shadow;
    border-radius: 20px;
    //标题
    h3{
      border-bottom: 1px solid #d8d8d8;
      margin: 0;
      padding: 15px 10px 5px 10px;
      font-weight: normal;
      font-size: 16px;
      color: $font-color-third;
    }
    p{
      margin: 10px 0 15px 7px;
      color: $font-color-second;
      input{
        position: relative;
        top: 1.5px;
      }
    }
    ul{
      margin: 0;
      padding: 0;
    }
    //分页
    .el-pagination{
      padding-bottom: 30px;
    }
  }
}
</style>
